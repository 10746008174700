import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Content, { HTMLContent } from "../components/Content";
import PageWrapper from "../components/PageWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title } from "../components/Core";
import CTA from "../sections/cases/CTA";
import CaseLeft from "../components/CaseLeft";
import { useIntl } from "react-intl";

const BlogContent = styled.div`
  img {
    width: 100%;
  }
`;

export const BusinessCaseTemplate = ({
  content,
  contentComponent,
  description,
  featuredimage,
  clientlogo,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <Section className="pb-0">
      {helmet}
      <div className="pt-3"></div>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="12">
            <Title variant="hero">{title}</Title>
          </Col>
        </Row>
      </Container>
      <CaseLeft
        description={description}
        clientlogo={clientlogo}
        featuredimage={featuredimage}
      />
      <div className="container content pb-5">
        <BlogContent>
          <PostContent content={content} />
        </BlogContent>
      </div>
    </Section>
  );
};

const BusinessCase = (props) => {
  const data = props.data;
  const intl = useIntl();

  return (
    <PageWrapper footerDark>
      <BusinessCaseTemplate
        content={data.case.html}
        contentComponent={HTMLContent}
        description={data.case.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Case">
            <title>{`${data.case.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${data.case.frontmatter.description}`}
            />
          </Helmet>
        }
        title={data.case.frontmatter.title}
        clientlogo={data.case.frontmatter.clientlogo}
        featuredimage={data.case.frontmatter.featuredimage}
      />
      <CTA
        collaborate={intl.formatMessage({
          id: "cases.collaborate",
          defaultMessage: "Also want to collaborate on a project?",
        })}
        collaborate_description={intl.formatMessage({
          id: "cases.collaborate_description",
          defaultMessage:
            "We're always open to discuss new partnerships and collaborations. Please reach out to use and we'll get back to you as soon as possible.",
        })}
        collaborate_cta={intl.formatMessage({
          id: "cases.collaborate_cta",
          defaultMessage: "Get in touch",
        })}
        langKey={props.data.case.fields.langKey}
      />
    </PageWrapper>
  );
};

export default BusinessCase;
